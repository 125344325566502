export const plansData = [
  {
    name: 'BASIC PLAN',
    price: '20',
    features: [
      '2 hours of excercises',
      'Free consultaion to coaches',
      'Access to The Community',
    ],
  },
  {
    name: 'PREMIUM PLAN',
    price: '35',
    features: [
      '5 hour of excercises',
      'Free consultaion of Coaches',
      'Accessto minibar',
    ],
  },
  {
    name: 'PRO PLAN',
    price: '40',
    features: [
      '8 hours of excercises',
      'Consultation of Coach',
      'Free Fitness Merchandises',
    ],
  },
]
