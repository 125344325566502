export const programsData = [
  {
    heading: 'Strength Training',
    details:
      'In this program, you are trained to improve your strength through many exercises.',
  },

  {
    heading: 'Fat Burning',
    details:
      'In this program, you are trained to improve your strength through many exercises.',
  },
  {
    heading: 'Health Fitness',
    details:
      'In this program, you are trained to improve your strength through many exercises.',
  },
  {
    heading: 'Cardio Training',
    details:
      'In this program, you are trained to improve your strength through many exercises.',
  },
]
